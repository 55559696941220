<template>
<div style="max-with: 300px; min-with: 300px" class="d-flex justify-content-center">
  <div id="chart"></div>
</div>
</template>

<script>
import ApexCharts from 'apexcharts'
import { mapGetters } from 'vuex';
import { map, size, reduce } from 'lodash'
import moment from 'moment';
moment.locale('pt-br')
export default {
  name: 'TagsGrafico',
  props: ['colors'],
  data () {
    return {
      count: 0,
      element: null,
      options:{
        noData: {
          text: 'Carregando...'
        },
        series: [],
        chart: {
          type: 'donut',
          animations: {
            enabled: true,
            easing: 'easeinout',
            dynamicAnimation: {
              enabled: true,
              speed: 500
            }
          }
        },
        legend: {
          show: false
        },
        dataLabels:{
          enabled: false,
          style: {
            // colors: ['#F44336', '#E91E63']
          }
        },
        yaxis: {
          labels: {
            formatter: (value, opts) => {
              return `${opts.config.legend[opts.seriesIndex]} ${value}%`
            }
          }
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            title: {
              formatter: () => {
                return ''
              }
            }
          }
        }
      }
    }
  },
  mounted () {
    this.options.colors = this.colors
    this.element = new ApexCharts(document.querySelector("#chart"), this.options)
    this.element.render()
    this.updateSeries()

  },
  watch: {
    'listTagsRecords' (val) {
      this.updateSeries(val)
      this.count++
    }
  },
  computed: {
    ...mapGetters('organizzeFinanceiroLancamentos', ['listTagsRecords']),
    formatData () {
      let dados = []
      const result = this.listTagsRecords
      if (size(result) === 0) {
        return dados
      }
      // eslint-disable-next-line
      const formatValores = map(result, v => parseFloat(v.value).toFixed(2) * -1)
      const total = reduce(formatValores, (a, b) => a + b)
      
      const valores = map(formatValores, v => Math.round((v / total) * 100))

      const labels = map(result, v => v.name )
      return { valores, labels }
    }
  },
  methods: {
    updateSeries (val) {
      console.log('val', val)
      if (size(val) === 0) {
         this.element.updateOptions({
           series: [],
           noData: {
             text: '..carregando!'
           }
         })
        return
      }
      this.element.updateOptions({
        series: this.formatData.valores,
        legend: this.formatData.labels
      })
    }
  }
}
</script>

<style lang="scss" scoped>
#chart {
  text-align: center;
  min-width: 100%;
  max-width: 100%;
  margin: 0 auto;
}
</style>